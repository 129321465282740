import React from 'react';

const Revievs = () => {
  return (
    <div className='revievs-container'>
      <iframe
        title='reveievs'
        src='https://d40524e3718e49c79ee572f074058d12.elf.site'
        frameborder='0'
      ></iframe>
    </div>
  );
};

export default Revievs;
